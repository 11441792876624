import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NewsDetailHeader from '../../components/News/NewsDetailHeader';
import NewsDetailContainer from '../../containers/News/NewsDetailContainer';

export default function NewsDetail(props) {
    const { t, i18n } = useTranslation();

    // These variables are set if the user enters with a routing parameter    
    let translateFunc = t;
    let i18nEngine = i18n;

    useEffect(() => {
        if (props.translateFunc == null || props.i18n == null) {
            translateFunc = t;
            i18nEngine = i18n;
        } else {
            translateFunc = props.translateFunc;
            i18nEngine = props.i18n;
        }
    }, [])

    return (
        <div>
            <NewsDetailHeader translateFunc={translateFunc} i18n={i18nEngine} />
            <NewsDetailContainer newsId={props.match != null ? props.match.params.newsId : null} />
        </div>
    );
}