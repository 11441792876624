import React, { useState, useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ModalsComments from '../../containers/_Global/ModalsComments';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineRounded';


export default function MoodInfluence(props) {

  const [commentModalOpenned, setCommentModalOpenned] = useState(false);
  const [commentModalTextValue, setCommentModalTextValue] = useState("");
  const [ratingCounter, setRatingCounter] = useState(0);

  const maxRatingValue = 5;

  useEffect(() => {

    if (props.associatedInfluenceEntry != null) {
      setRatingCounter(props.associatedInfluenceEntry.rating);
    }    

    setCommentModalTextValue(
      props.associatedInfluenceEntry != null
        ? props.associatedInfluenceEntry.commentText
        : "");
  }, [props.associatedInfluenceEntry])

  const openCommentModal = () => {
    setCommentModalOpenned(true);
  };

  const closeCommentModal = (textValue) => {
    if (textValue !== commentModalTextValue) {
      setCommentModalTextValue(textValue)
      props.onInfluenceEntryCommentChanged(props.associatedInfluenceEntry.id, textValue);
    }
    setCommentModalOpenned(false);
  };

  function getThumbIconClass(buttonType, infEntry) {
    if (infEntry != null) {

      if (infEntry.rating === 1 && buttonType === "thumbsUp") {
        return "thumbIcon like";
      }
      else if (infEntry.rating === -1 && buttonType === "thumbsDown") {
        return "thumbIcon unlike";
      }
    }


    return "thumbIcon";
  }

  function renderChatBubbltIcon() {
    if (props.associatedInfluenceEntry != null
      && props.associatedInfluenceEntry.rating !== 0) {
      return (
        <IconButton aria-label="comment" style={{ marginRight: '10px' }} onClick={openCommentModal}>
          {commentModalTextValue != null && commentModalTextValue.length
            ? <ChatBubbleIcon />
            : <ChatBubbleOutlineIcon />}
        </IconButton>
      );
    }
    else {
      return (<IconButton aria-label="comment" style={{ marginRight: '35px' }} />);
    }
  }

  function onCounterUpdateClick(isToIncrement) {
    var newRatingValue = 0;
    if (isToIncrement == true) {
      newRatingValue = (ratingCounter + 1) % (maxRatingValue + 1);

    } else {
      newRatingValue = ratingCounter - 1;
      if (newRatingValue < 0) {
        newRatingValue = maxRatingValue;
      }
    }

    setRatingCounter(newRatingValue);

    props.onThumbClickFunction(
      newRatingValue,
      props.associatedInfluenceEntry,
      props.influenceId,
      props.influenceCategoryId)
  }

  return (
    <ListItem>
      {renderChatBubbltIcon()}
      <ListItemText
        primary={props.influenceTitle}
      />

      <IconButton
        edge="end"
        aria-label="Open"
        onClick={() => onCounterUpdateClick(false)}>
        <RemoveIcon fontSize='large' />

      </IconButton>

      <div>
        <ListItemText>
          {ratingCounter}
        </ListItemText>
      </div>

      <IconButton
        edge="end"
        aria-label="Open"
        onClick={() => onCounterUpdateClick(true)}>
        <AddIcon fontSize='large' />

      </IconButton>

      {/* Render comment modal if the button was clicked 
        If the commentModalOpenned is false we dont want to show the modal
      */}
      {commentModalOpenned
        ? <ModalsComments
          userName={props.userName}
          pictureToDisplay={props.profilePictureFullPath}
          preFilledValue={commentModalTextValue}
          onModalClosed={closeCommentModal} />
        : null}


    </ListItem>

  );
}