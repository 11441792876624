
import { fetchWithBearerToken, stringFormat } from '../utils/utils';

export function getCompanyNewsList(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_NEWS_GETCOMPANY_NEWS,
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function getNewsById(newsId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_NEWS_GET_BY_ID, newsId),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function saveNewsAsRead(newsId) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_NEWS_SAVE_NEWS_AS_READ, newsId),
        "POST")
        .then(response => response);
}

export function getUnreadNewsCount(cacheCallback) {

    return fetchWithBearerToken(
        process.env.REACT_APP_URL_API_NEWS_GET_UNREAD_NEWS_COUNT,
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}

export function isNewsReadByUser(newsId, cacheCallback) {

    return fetchWithBearerToken(
        stringFormat(process.env.REACT_APP_URL_API_NEWS_IS_NEWS_READ, newsId),
        "GET",
        null,
        cacheCallback)
        .then(response => response);
}