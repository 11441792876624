import React, { useEffect } from 'react';
import NewsListHeader from '../../components/News/NewsListHeader';
import NewsListContainer from '../../containers/News/NewsListContainer';


export default function NewsContainer(props) {
    useEffect(() => {
        //props.i18n.changeLanguage("en");
    }, [])

    return (
        <div>
            <NewsListHeader translateFunc={props.translateFunc} i18n={props.i18n} />
            <NewsListContainer />
        </div>
    );
}
