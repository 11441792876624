import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as newsService from '../../api/newsService';
import parse from 'html-react-parser';
import { ReactComponent as LoadingComponent } from '../../images/loading.svg';
import * as coinService from '../../api/coinService';
import * as modalUtils from '../../utils/modalUtils';
import ModalsHeaderHappycoinsWon from '../../containers/_Global/ModalsHeaderHappycoinsWon';

function NewsDetailContainer(props) {

    const [directLinkNewsTitle, setDirectLinkNewsTitle] = useState("");
    const [newsImageURL, setNewsImageURL] = useState("");
    const [directLinkNewsMessageContent, setDirectLinkNewsMessageContent] = useState("");
    const [newsDateCreated, setNewsDateCreated] = useState();
    const [canShowNewsFromDirectLink, setCanShowNewsFromDirectLink] = useState(false);
    const [videoId, setVideoId] = useState();


    useEffect(() => {
        let actualNewsId = props.newsId != null ? props.newsId : props.selectedNews.id;

        if (actualNewsId != null) {
            newsService.saveNewsAsRead(actualNewsId);

            newsService.getNewsById(actualNewsId, handleNewsDetailData)
                .then(news => {
                    handleNewsDetailData(news);
                });
        }

    }, [])

    useEffect(() => {
        let actualNewsId = props.newsId != null ? props.newsId : props.selectedNews.id;

        if (actualNewsId != null) {
            newsService.isNewsReadByUser(actualNewsId).then(userReadNews => {

                if (!userReadNews) {
                    coinService.giveCoinsToUserFromReadingNews().then(coinTransaction => {
                        if (coinTransaction != null && coinTransaction.quantity !== 0) {

                            var titleText = coinTransaction.quantity === 1
                                ? "Ganhaste " + 1 + " Happycoin"
                                : "Ganhaste " + coinTransaction.quantity + " Happycoins";

                            modalUtils.displayModalImageSimpleConfirm(
                                <ModalsHeaderHappycoinsWon />,
                                titleText,
                                "Consulta todas as nossas notícias e artigos para ganhares mais.",
                                "Ok");
                        }

                    });

                }
            });
        }



    }, [])

    function handleNewsDetailData(news) {
        setCanShowNewsFromDirectLink(true);
        setNewsDetails(news);

    }

    function setNewsDetails(news) {

        if (news != null) {

            setDirectLinkNewsTitle(news.title);
            setNewsDateCreated(new Date(news.dateCreated).toLocaleDateString());
            setDirectLinkNewsMessageContent(news.messageContent);
            setNewsImageURL(news.imageURL);
            setVideoId(news.videoId);
        }
    }

    /// Renders the news block coming from the news list
    function renderNewsFromList() {
        return (
            <div className="NewsTxtDetail">
                <div className="NewsTitle">{props.selectedNews != null ? props.selectedNews.title : ""}</div>
                <div className="NewsText">{props.selectedNews != null ? parse(props.selectedNews.messageContent) : ""}</div>
                <br />
                <div className="NewsText">{new Date(props.selectedNews.dateCreated).toLocaleDateString()}</div>
            </div>
        );
    }

    /// Renders the news block from a direct link with a news ID
    function renderNewsFromDirectLink() {
        if (!canShowNewsFromDirectLink) {
            return (<LoadingComponent />);
        }

        return (

            <div className="NewsTxtDetail">
                <div className="NewsTitle">{directLinkNewsTitle}</div>
                <div className="NewsText">{parse(directLinkNewsMessageContent)}</div>

                <br />

                <div className="NewsText">{newsDateCreated}</div>
            </div>
        );
    }

    function renderMedia() {
        if (videoId) {
            return (
                <iframe width="100%" height="100%"
                    src={"https://www.youtube.com/embed/" + videoId}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />);
        } else {

        }
        return (<img src={newsImageURL ? newsImageURL : props.selectedNews.imageURL} />);
    }


    return (
        <div className="NewsDetail">
            <div className="NewsDetailImg">
                {/* <img src={require("../../images/news.jpg")} />                 */}
                {renderMedia()}



            </div>


            {props.newsId != null ? renderNewsFromDirectLink() : renderNewsFromList()}

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedNews: state.newsReducer.selectedNews
    };
}

export default connect(mapStateToProps)(NewsDetailContainer);