// Contains the mood calls
import { fetchWithBearerToken, stringFormat } from '../utils/utils';

export function getUserTotalCoinsForCompany(cacheCallback) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_COINS_GET_COINS,
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function giveCoinsToUserFromMoodFill(questionAnswers) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_COINS_GIVE_COINS_MOOD_FILL,
    "POST",
    questionAnswers)
    .then(response => {
      return response;
    });
}

export function giveCoinsToUserFromQuestionAnswered(questionAnswers) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_COINS_GIVE_COINS_QUESTIONS_ANSWERED,
    "POST",
    questionAnswers)
    .then(response => {
      return response;
    });
}

export function giveCoinsToUserFromAppraisalsGiven(appraisalAmmount) {

  return fetchWithBearerToken(
    stringFormat(process.env.REACT_APP_URL_API_COINS_GIVE_COINS_APPRAISALS_GIVEN, appraisalAmmount),
    "POST")
    .then(response => {
      return response;

    });
}

export function giveCoinsToUserFromReadingNews() {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_COINS_GIVE_COINS_NEWS_READ,
    "POST")
    .then(response => {
      return response;

    });
}


export function getCoinTransactionHistory(cacheCallback) {

  return fetchWithBearerToken(
    process.env.REACT_APP_URL_API_COINS_GET_COIN_TRANSACTION_HISTORY,
    "GET",
    null,
    cacheCallback)
    .then(response => response);
}

export function getAcquiredCoisLastDays(lastDaysAmount, cacheCallback) {

  return fetchWithBearerToken(
    stringFormat(process.env.REACT_APP_URL_API_COINS_GET_ACQUIRED_LAST_DAYS_AMOUNT, lastDaysAmount),
    "GET",
    null,
    cacheCallback)
    .then(response => {
      return response;

    });
}



export function getAcquiredCoinsLastWeeks(numberOfWeeksToGet, cacheCallback) {

  return fetchWithBearerToken(
    stringFormat(process.env.REACT_APP_URL_API_COINS_GET_ACQUIRED_LAST_WEEKS, numberOfWeeksToGet),
    "GET",
    null,
    cacheCallback)
    .then(response => {
      return response;

    });
}

export function getCoinRewardByCode(code, cacheCallback) {

  return fetchWithBearerToken(
    stringFormat(process.env.REACT_APP_URL_API_COINS_GET_REWARD_BY_CODE, code),
    "GET",
    null,
    cacheCallback)
    .then(response => {
      return response;

    });
}

