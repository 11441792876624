import React, { useEffect } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { materialOverride } from "../../css/globalStyles.js";
import Drawer from '../../components/_Global/Drawer'
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useHistory } from 'react-router-dom';
import * as newsService from '../../api/newsService.js';
import { connect } from 'react-redux';
import * as userActions from '../../redux/actions/userActions';
import PieChartIcon from '@material-ui/icons/PieChart';
import HelpIcon from '@material-ui/icons/Help';
import { showTutorialPopup } from '../../utils/modalUtils.js';
import { ReactComponent as ModalIcon } from '../../images/Icons/smiles_daily_objective.svg';
import TutorialCard from '../../components/Tutorials/TutorialCard.jsx';


const logo = require('../../images/hci_logo.png');

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    logo: {
        width: '65px',
        position: 'absolute',
        top: '12px',
        left: '50%',
        transform: 'translate(-50%, 0)'
    },
}));

function HeaderContainer(props) {

    const classes = useStyles();
    const history = useHistory();
    const [unreadNewsCount, setUnreadNewsCount] = React.useState(0);

    let homepageTutorialCardsContent = [];
    homepageTutorialCardsContent.push(
        <div key={1}>
            <TutorialCard
                imageComponentToRender={<ModalIcon />}
                title={""}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_HOMEPAGE_WELCOME")} />
        </div>);

    homepageTutorialCardsContent.push(
        <div key={2}>
            <TutorialCard
                imageComponentToRender={<ModalIcon />}
                title={props.translateFunc("TUTORIAL_HOMEPAGE_MOOD_TITLE")}
                subTitle={""}
                modalMessage={props.translateFunc("TUTORIAL_HOMEPAGE_MOOD")} />
        </div>);

    homepageTutorialCardsContent.push(
        <div key={3}>
            <TutorialCard
                imageComponentToRender={<ModalIcon />}
                title={"48"}
                subTitle={props.translateFunc("TUTORIAL_HOMEPAGE_MERIT_BOARD_TITLE")}
                modalMessage={props.translateFunc("TUTORIAL_HOMEPAGE_MERIT_BOARD")} />
        </div>);

    homepageTutorialCardsContent.push(
        <div key={4}>
            <TutorialCard
                imageComponentToRender={<ModalIcon />}
                title={"114"}
                subTitle={props.translateFunc("TUTORIAL_HOMEPAGE_HAPPYCOINS_TITLE")}
                modalMessage={props.translateFunc("TUTORIAL_HOMEPAGE_HAPPYCOINS")} />
        </div>);

    function goToPage(link) {
        history.push(link);
    }

    useEffect(() => {
        newsService.getUnreadNewsCount(setUnreadNewsCount).then(c => {
            setUnreadNewsCount(c);
        })
        props.i18n.changeLanguage(props.userLanguage);

    }, [])

    function toggleLanguage() {

        if (props.i18n.language === "en" || props.i18n.language === "en-US") {
            props.i18n.changeLanguage("pt");
            props.setUserLanguage("pt");

        } else if (props.i18n.language === "pt" || props.i18n.language === "pt-PT") {
            props.i18n.changeLanguage("en");
            props.setUserLanguage("en");
        }

    }

    return (
        <div className={classes.grow}>
            <AppBar position="static" style={materialOverride.transparentColor}>
                <Toolbar>
                    <Drawer translateFunc={props.translateFunc}></Drawer>
                    <img src={logo} alt={'Alt text here'} className={classes.logo} />
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>

                        <IconButton aria-label="info here" color="inherit" onClick={() => { goToPage(process.env.REACT_APP_ROUTING_USER_CHARTS) }}>
                            <Badge badgeContent={0} color="secondary">
                                <PieChartIcon />

                            </Badge>
                        </IconButton>

                        <IconButton aria-label="info here" color="inherit" onClick={() => { goToPage(process.env.REACT_APP_ROUTING_MENU_NEWS) }}>
                            <Badge badgeContent={unreadNewsCount} color="secondary">
                                <ListAltIcon />
                            </Badge>
                        </IconButton>

                        <IconButton aria-label="info here" color="inherit" onClick={() => {
                            showTutorialPopup(homepageTutorialCardsContent, "Homepage", props.i18n, props.translateFunc);
                        }}>
                            <HelpIcon />
                        </IconButton>

                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userLanguage: state.userReducer.userLanguage
    }
}

const mapDispatchToProps = dispatch => {

    return {
        setUserLanguage: (lang) => dispatch(
            userActions.setUserLanguage(lang)
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);